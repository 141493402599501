import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  Container,
  Header,
  Form,
  Input,
  TextArea,
  Checkbox,
  Button,
  Divider,
  Popup
} from "semantic-ui-react";
import "../index.css";

import CustomMenu from "../container/CustomMenu";
import PageHeader from "../container/PageHeader";
import Footer from "../container/Footer";
import MobileNav from "../container/MobileNav";
import Meta from "./Meta";

const Contact = () => (
  <Grid
    centered
    style={
      {
        // backgroundColor: '#f8f8f8'
      }
    }
  >
    <Grid.Row
      padded
      style={{
        marginTop: "5rem",
        marginBottom: "3rem"
      }}
    >
      <Form
        size="large"
        style={{
          width: "30%"
        }}
      >
        <Form.Field
          control={Input}
          label="Full Name"
          style={{
            margin: "0 auto",
            border: "1px #ccc",
            borderColor: "#bdc3c7",
            borderRadius: 20
          }}
        />
        <Form.Field control={Input} label="Email*" />
        <Form.Field control={TextArea} label="Your Question*" />
        <Form.Field
          control={Checkbox}
          label="I agree to the Terms and Conditions"
        />
        <Popup
          trigger={<Form.Field control={Button}>Submit</Form.Field>}
          content="Invalid"
          hideOnScroll
        />
      </Form>
    </Grid.Row>
    <Divider horizontal>Or</Divider>
    <Grid.Row
      padded
      style={{
        // backgroundColor: '#f8f8f8'
        marginTop: "3rem",
        marginBottom: "6rem"
      }}
    >
      <p>By email: support@joatspace.com</p>
    </Grid.Row>
  </Grid>
);

const Disclaimer = () => (
  <Grid
    padded
    style={
      {
        // backgroundColor: '#f8f8f8'
      }
    }
  >
    <Container
      text
      style={{
        // backgroundColor: '#f8f8f8'
        marginTop: "5rem",
        marginBottom: "8rem"
      }}
    >
      <p>
        The information contained on website and JoatSpace mobile app (the
        "Service") is for general information purposes only.
      </p>

      <p>
        JoatSpace Inc. assumes no responsibility for errors or omissions in the
        contents on the Service.
      </p>

      <p>
        In no event shall JoatSpace Inc. be liable for any special, direct,
        indirect, consequential, or incidental damages or any damages
        whatsoever, whether in an action of contract, negligence or other tort,
        arising out of or in connection with the use of the Service or the
        contents of the Service. JoatSpace Inc. reserves the right to make
        additions, deletions, or modification to the contents on the Service at
        any time without prior notice.
      </p>

      <p>
        JoatSpace Inc. does not warrant that the Service is free of viruses or
        other harmful components.
      </p>

      <Header as="h2">External links disclaimer</Header>

      <p>
        {" "}
        website and JoatSpace mobile app may contain links to external websites
        that are not provided or maintained by or in any way affiliated with
        JoatSpace Inc.
      </p>

      <p>
        Please note that the JoatSpace Inc. does not guarantee the accuracy,
        relevance, timeliness, or completeness of any information on these
        external websites.
      </p>
    </Container>
  </Grid>
);

class SupportPage extends Component {
  componentDidUpdate(prevProps) {
    ReactDOM.findDOMNode(this).scrollIntoView();
    // Location
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <Meta
          title="Support"
          description="Get instant access to top-rated taskers around you. Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
          url="https://www.joatspace.com"
          copyright="Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
        />
        <MobileNav /> {/* Custom Menu for Mobile */}
        <CustomMenu /> {/* Custom Menu for Desktop and Tablet Only */}
        <PageHeader
          title="Welcome to JoatSpace Support"
          desc="We are here to help"
        />
        <Contact />
        <PageHeader
          title="Disclaimer"
          desc="Last updated: September 06, 2018"
        />
        <Disclaimer />
        <Footer />
      </div>
    );
  }
}

export default SupportPage;
