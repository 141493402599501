import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import "../index.css";

import AppStoreIcon from "./AppStoreIcon";
import logoCircle from "../img/logoCircle.svg";
import register from "../img/register.svg";
import occupation from "../img/occupation.svg";
import rates from "../img/rate.svg";
import schedule from "../img/schedule.svg";

const BecomeATaskerTitle = () => (
  <Grid.Row
    centered
    style={{
      marginTop: "6.25rem"
    }}
  >
    <label
      style={{
        fontFamily: "Open Sans",
        fontSize: "2.5rem",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "0.075rem",
        textAlign: "center",
        color: "#3f3f3f"
      }}
    >
      Become a Tasker
    </label>
  </Grid.Row>
);

const BecomeATaskerDesc = () => (
  <Grid.Row centered>
    <label
      style={{
        fontFamily: "Open Sans",
        fontSize: "1.375rem",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "0.7px",
        textAlign: "center",
        color: "#7a7a7a"
      }}
    >
      Work With JoatSpace
    </label>
  </Grid.Row>
);

const BecomeATaskerTimeline = props => (
  <Grid.Column
    textAlign="center"
    mobile={16}
    computer={2}
    style={{
      marginTop: "2.5rem",
      marginLeft: "3rem",
      zIndex: "2"
    }}
  >
    <Image src={props.img} centered />
    <Grid.Row
      centered
      style={{
        marginTop: "2.5rem"
      }}
    >
      <label
        style={{
          fontFamily: "Open Sans",
          fontSize: "1.125rem",
          fontWeight: "normal",
          fontStyle: "normal",
          fontStretch: "normal",
          lineHeight: "normal",
          letterSpacing: "0.6px",
          textAlign: "center",
          color: "#3f3f3f"
        }}
      >
        {props.title}
      </label>
    </Grid.Row>
  </Grid.Column>
);

class BecomeATasker extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);

  render() {
    return (
      <Grid
        padded
        style={{
          backgroundColor: "#f8f8f8"
        }}
      >
        <div ref="becomeATasker" />
        <BecomeATaskerTitle />
        <BecomeATaskerDesc />

        <Grid.Row centered>
          <BecomeATaskerTimeline
            title="Download the JoatSpace App"
            img={logoCircle}
          />
          <BecomeATaskerTimeline
            title="Register to be a Tasker"
            img={register}
          />
          <BecomeATaskerTimeline
            title="Choose your Occupation"
            img={occupation}
          />
          <BecomeATaskerTimeline title="Choose your Rates" img={rates} />
          <BecomeATaskerTimeline
            title="Choose a Schedule that works best for you"
            img={schedule}
          />
        </Grid.Row>

        <Grid.Row only="computer">
          {/* Line Between Timeline */}
          <hr
            style={{
              width: "65vw",
              height: "0.18rem",
              border: "none",
              marginTop: "-14.5rem",
              color: "#43e695",
              backgroundColor: "#43e695",
              zIndex: "1"
            }}
          />
        </Grid.Row>

        <Grid.Row
          centered
          style={{
            marginTop: "2.2rem",
            marginBottom: "6.25rem"
          }}
        >
          <AppStoreIcon />
        </Grid.Row>
      </Grid>
    );
  }
}

export default BecomeATasker;
