import React, { Component } from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "../img/logowithGradient.svg";
import "../index.css";

class CustomMenu extends Component {
  downloadAppoadApp = () => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://itunes.apple.com/us/app/joatspace/id1434912789";
    }

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.exampleapp";
    }

    //Update #2
    if (
      !navigator.userAgent.match(
        /(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/
      )
    ) {
      window.location.href = "https://www.joatspace.com/download"; //Desktop Browser
    }
  };

  render() {
    return (
      <Grid
        padded
        style={{
          backgroundColor: "#2a2a2a",
          overflow: "hidden",
          position: "fixed" /* Set the navbar to fixed position */,
          top: "0",
          width: "100%",
          zIndex: "1"
        }}
      >
        <Grid.Row only="computer" columns={2}>
          <Grid.Column>
            <a href=".">
              <Image
                src={logo}
                style={{
                  marginTop: "1rem",
                  marginLeft: "7rem",
                  marginBottom: "1rem"
                }}
              />
            </a>
          </Grid.Column>

          <Grid.Column
            style={{
              marginTop: "2.3rem"
            }}
            width={8}
            floated="right"
          >
            <Link to="/terms" className="Link">
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className="Link">
              Privacy Policy
            </Link>
            <Link to="/eula" className="Link">
              EULA
            </Link>
            <Link to="/support" className="Link">
              Support
            </Link>
            <Button onClick={this.downloadAppoadApp} className="Link">
              Download
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default CustomMenu;
