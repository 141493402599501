import React, { Component } from "react";
import { Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "../img/logo.svg";
import phone from "../img/iPhoneSamsungPNG.png";
import AppStoreIcon from "../container/AppStoreIcon";
import Footer from "../container/Footer";

import "../index.css";
import Meta from "./Meta";

const Nav = () => (
  <Grid.Row only="computer" columns={2}>
    <Grid.Column>
      <a href=".">
        <Image
          src={logo}
          style={{
            marginTop: "2.5rem",
            marginLeft: "7rem"
          }}
        />
      </a>
    </Grid.Column>

    <Grid.Column
      style={{
        marginTop: "3.2rem",
        marginRight: "2rem"
      }}
      width={7}
      floated="right"
    >
      <a href="." className="Link">
        Home
      </a>
      <a className="Link" href="#howItWorks">
        How it Works
      </a>
      <a className="Link" href="#becomeATasker">
        Become a Tasker
      </a>
      <Link to="/download" className="Link">
        Download
      </Link>
    </Grid.Column>
  </Grid.Row>
);

const Title = () => (
  <Grid.Column
    mobile={16}
    computer={8}
    style={{
      marginTop: "11rem"
    }}
  >
    {/* JoatSpace */}
    <Grid.Row>
      <label className="JoatSpace">Download JoatSpace</label>
    </Grid.Row>
    {/*  Description */}
    <Grid.Row
      stretched={true}
      style={{
        marginTop: "0.6rem"
      }}
    >
      <label className="JoatSpaceDesc">
        Get instant access to top-rated taskers around you.
      </label>
    </Grid.Row>
    {/* App Store Icons */}
    <Grid.Row
      only="computer"
      style={{
        marginLeft: "6.25rem",
        marginTop: "3.2rem"
      }}
    >
      <AppStoreIcon />
    </Grid.Row>
  </Grid.Column>
);

const PhoneImg = () => (
  <Grid.Column
    computer={7}
    style={{
      marginBottom: "6.5rem"
    }}
    floated="right"
  >
    <Image
      style={{
        paddingRight: "10rem"
      }}
      src={phone}
    />
  </Grid.Column>
);

const JoatSpaceDescAndPhoneDesktopOnly = () => (
  <Grid.Row
    only="computer"
    style={{
      height: "85vh"
    }}
  >
    {/* JoatSpace and Description */}
    <Title />
    {/* Phone Image */}
    <PhoneImg />
  </Grid.Row>
);

// Joat Header for Mobile And Desktop
const JoatSpaceDescAndPhoneMobileAndTablet = () => (
  <Grid.Row centered only="mobile tablet">
    <Grid.Column mobile={14} tablet={11}>
      {/* JoatSpace Logo */}
      <Grid.Row>
        <a href=".">
          <Image
            centered
            src={logo}
            style={{
              marginTop: "2.5rem"
            }}
          />
        </a>
      </Grid.Row>

      {/* Phone Image */}
      <Grid.Row
        centered
        style={{
          marginTop: "3.2rem"
        }}
      >
        <Image src={phone} />
      </Grid.Row>

      {/* JoatSpace */}
      <Grid.Row
        centered
        style={{
          marginTop: "3.2rem"
        }}
      >
        <label
          style={{
            fontFamily: "Open Sans",
            fontSize: "2.2rem",
            fontWeight: "bold",
            textAlign: "center",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "1.2px",
            color: "#ffffff"
          }}
        >
          Download JoatSpace
        </label>
      </Grid.Row>

      {/*  Description */}
      <Grid.Row
        style={{
          marginTop: "0.6rem"
          // marginBottom: '10rem'
        }}
      >
        <label
          style={{
            fontFamily: "Open Sans",
            fontSize: "1.2rem",
            fontWeight: "normal",
            textAlign: "center",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.7px",
            color: "#ffffff"
          }}
        >
          Get instant access to top-rated taskers around you.
        </label>
      </Grid.Row>

      {/* App Store Icons */}
      <Grid.Row
        centered
        only="mobile tablet"
        style={{
          marginTop: "3rem",
          marginBottom: "5rem"
        }}
      >
        <AppStoreIcon />
      </Grid.Row>
    </Grid.Column>
  </Grid.Row>
);

class Download extends Component {
  render() {
    return (
      <div>
        <Meta
          title="Download JoatSpace"
          description="Download Snapchat for iOS and Android. Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
          url="https://www.joatspace.com/download"
          copyright="Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
        />
        <Grid
          padded
          className="home_bg"
          style={
            {
              // height: '100vh'
            }
          }
        >
          {/* Only Desktop */}
          <Nav />
          <JoatSpaceDescAndPhoneDesktopOnly />

          {/* Only Mobile */}
          <JoatSpaceDescAndPhoneMobileAndTablet />
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default Download;
