import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Grid, Container, Header, Divider } from "semantic-ui-react";
import "../index.css";

import CustomMenu from "../container/CustomMenu";
import PageHeader from "../container/PageHeader";
import Footer from "../container/Footer";
import MobileNav from "../container/MobileNav";
import Meta from "./Meta";

const Agreement = () => (
  <Grid
    padded
    style={
      {
        // backgroundColor: '#f8f8f8'
      }
    }
  >
    <Container
      text
      style={{
        // backgroundColor: '#f8f8f8'
        marginTop: "5rem",
        marginBottom: "8rem"
      }}
    >
      <p>
        Please read this End-User License Agreement ("Agreement") carefully
        before clicking the "I Agree" button, downloading or using JoatSpace
        ("Application").
      </p>

      <p>
        By clicking the "I Agree" button, downloading or using the Application,
        you are agreeing to be bound by the terms and conditions of this
        Agreement.
      </p>

      <p>
        This Agreement is a legal agreement between you (either an individual or
        a single entity) and Joatspace Inc. and it governs your use of the
        Application made available to you by Joatspace Inc..
      </p>

      <p>
        If you do not agree to the terms of this Agreement, do not click on the
        "I Agree" button and do not download or use the Application.
      </p>

      <p>
        The Application is licensed, not sold, to you by Joatspace Inc. for use
        strictly in accordance with the terms of this Agreement.
      </p>

      <Header as="h2">License</Header>

      <p>
        Joatspace Inc. grants you a revocable, non-exclusive, non-transferable,
        limited license to download, install and use the Application strictly in
        accordance with the terms of this Agreement.
      </p>

      <Header as="h2">Restrictions</Header>

      <p>You agree not to, and you will not permit others to:</p>

      <ul>
        <li>
          <p>
            modify, make derivative works of, disassemble, decrypt, reverse
            compile or reverse engineer any part of the Application.
          </p>
        </li>
        <li>
          <p>
            remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) of Joatspace Inc. or its
            affiliates, partners, suppliers or the licensors of the Application.
          </p>
        </li>
      </ul>

      <Header as="h2">Intellectual Property</Header>

      <p>
        The Application, including without limitation all copyrights, patents,
        trademarks, trade secrets and other intellectual property rights are,
        and shall remain, the sole and exclusive property of Joatspace Inc..
      </p>

      <Header as="h2">Your Suggestions</Header>

      <p>
        Any feedback, comments, ideas, improvements or suggestions
        (collectively, "Suggestions") provided by you to Joatspace Inc. with
        respect to the Application shall remain the sole and exclusive property
        of Joatspace Inc..
      </p>

      <p>
        Joatspace Inc. shall be free to use, copy, modify, publish, or
        redistribute the Suggestions for any purpose and in any way without any
        credit or any compensation to you.
      </p>

      <Header as="h2">Modifications to Application</Header>

      <p>
        Joatspace Inc. reserves the right to modify, suspend or discontinue,
        temporarily or permanently, the Application or any service to which it
        connects, with or without notice and without liability to you.
      </p>

      <Header as="h2">Updates to Application</Header>

      <p>
        Joatspace Inc. may from time to time provide enhancements or
        improvements to the features/functionality of the Application, which may
        include patches, bug fixes, updates, upgrades and other modifications
        ("Updates").
      </p>

      <p>
        Updates may modify or delete certain features and/or functionalities of
        the Application. You agree that Joatspace Inc. has no obligation to (i)
        provide any Updates, or (ii) continue to provide or enable any
        particular features and/or functionalities of the Application to you.
      </p>

      <p>
        You further agree that all Updates will be (i) deemed to constitute an
        integral part of the Application, and (ii) subject to the terms and
        conditions of this Agreement.
      </p>

      <Header as="h2">Third-Party Services</Header>

      <p>
        The Application may display, include or make available third-party
        content (including data, information, applications and other products
        services) or provide links to third-party websites or services
        ("Third-Party Services").
      </p>

      <p>
        You acknowledge and agree that Joatspace Inc. shall not be responsible
        for any Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality
        or any other aspect thereof. Joatspace Inc. does not assume and shall
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Services.
      </p>

      <p>
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and conditions.
      </p>

      <Header as="h2">Privacy Policy</Header>

      <p>
        Joatspace Inc. collects, stores, maintains, and shares information about
        you in accordance with its Privacy Policy, which is available at
        https://www.joatspace.com/privacy-policy. By accepting this Agreement,
        you acknowledge that you hereby agree and consent to the terms and
        conditions of our Privacy Policy.
      </p>

      <Header as="h2">Term and Termination</Header>

      <p>
        This Agreement shall remain in effect until terminated by you or
        Joatspace Inc..
      </p>

      <p>
        Joatspace Inc. may, in its sole discretion, at any time and for any or
        no reason, suspend or terminate this Agreement with or without prior
        notice.
      </p>

      <p>
        This Agreement will terminate immediately, without prior notice from
        Joatspace Inc., in the event that you fail to comply with any provision
        of this Agreement. You may also terminate this Agreement by deleting the
        Application and all copies thereof from your mobile device or from your
        computer.
      </p>

      <p>
        Upon termination of this Agreement, you shall cease all use of the
        Application and delete all copies of the Application from your mobile
        device or from your computer.
      </p>

      <p>
        Termination of this Agreement will not limit any of Joatspace Inc.'s
        rights or remedies at law or in equity in case of breach by you (during
        the term of this Agreement) of any of your obligations under the present
        Agreement.
      </p>

      <Header as="h2">Indemnification</Header>

      <p>
        You agree to indemnify and hold Joatspace Inc. and its parents,
        subsidiaries, affiliates, officers, employees, agents, partners and
        licensors (if any) harmless from any claim or demand, including
        reasonable attorneys' fees, due to or arising out of your: (a) use of
        the Application; (b) violation of this Agreement or any law or
        regulation; or (c) violation of any right of a third party.
      </p>

      <Header as="h2">No Warranties</Header>

      <p>
        The Application is provided to you "AS IS" and "AS AVAILABLE" and with
        all faults and defects without warranty of any kind. To the maximum
        extent permitted under applicable law, Joatspace Inc., on its own behalf
        and on behalf of its affiliates and its and their respective licensors
        and service providers, expressly disclaims all warranties, whether
        express, implied, statutory or otherwise, with respect to the
        Application, including all implied warranties of merchantability,
        fitness for a particular purpose, title and non-infringement, and
        warranties that may arise out of course of dealing, course of
        performance, usage or trade practice. Without limitation to the
        foregoing, Joatspace Inc. provides no warranty or undertaking, and makes
        no representation of any kind that the Application will meet your
        requirements, achieve any intended results, be compatible or work with
        any other software, applications, systems or services, operate without
        interruption, meet any performance or reliability standards or be error
        free or that any errors or defects can or will be corrected.
      </p>

      <p>
        Without limiting the foregoing, neither Joatspace Inc. nor any Joatspace
        Inc.'s provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Application, or the information, content, and materials or products
        included thereon; (ii) that the Application will be uninterrupted or
        error-free; (iii) as to the accuracy, reliability, or currency of any
        information or content provided through the Application; or (iv) that
        the Application, its servers, the content, or e-mails sent from or on
        behalf of Joatspace Inc. are free of viruses, scripts, trojan horses,
        worms, malware, timebombs or other harmful components.
      </p>

      <p>
        Some jurisdictions do not allow the exclusion of or limitations on
        implied warranties or the limitations on the applicable statutory rights
        of a consumer, so some or all of the above exclusions and limitations
        may not apply to you.
      </p>

      <Header as="h2">Limitation of Liability</Header>

      <p>
        Notwithstanding any damages that you might incur, the entire liability
        of Joatspace Inc. and any of its suppliers under any provision of this
        Agreement and your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by you for the Application.
      </p>

      <p>
        To the maximum extent permitted by applicable law, in no event shall
        Joatspace Inc. or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, for loss of data or other
        information, for business interruption, for personal injury, for loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Application, third-party software and/or third-party hardware
        used with the Application, or otherwise in connection with any provision
        of this Agreement), even if Joatspace Inc. or any supplier has been
        advised of the possibility of such damages and even if the remedy fails
        of its essential purpose.
      </p>

      <p>
        Some states/jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages, so the above limitation or
        exclusion may not apply to you.
      </p>

      <Header as="h2">Severability</Header>

      <p>
        If any provision of this Agreement is held to be unenforceable or
        invalid, such provision will be changed and interpreted to accomplish
        the objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>

      <Header as="h2">Waiver</Header>

      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Agreement shall not effect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall be the waiver of a breach constitute
        waiver of any subsequent breach.
      </p>

      <Header as="h2">For U.S. Government End Users</Header>

      <p>
        The Application and related documentation are "Commercial Items", as
        that term is defined under 48 C.F.R. §2.101, consisting of "Commercial
        Computer Software" and "Commercial Computer Software Documentation", as
        such terms are used under 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as
        applicable. In accordance with 48 C.F.R. §12.212 or 48 C.F.R.
        §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer
        Software and Commercial Computer Software Documentation are being
        licensed to U.S. Government end users (a) only as Commercial Items and
        (b) with only those rights as are granted to all other end users
        pursuant to the terms and conditions herein.
      </p>

      <Header as="h2">Export Compliance</Header>

      <p>
        You may not export or re-export the Application except as authorized by
        United States law and the laws of the jurisdiction in which the
        Application was obtained.
      </p>

      <p>
        In particular, but without limitation, the Application may not be
        exported or re-exported (a) into or to a nation or a resident of any
        U.S. embargoed countries or (b) to anyone on the U.S. Treasury
        Department's list of Specially Designated Nationals or the U.S.
        Department of Commerce Denied Person's List or Entity List.
      </p>

      <p>
        By installing or using any component of the Application, you represent
        and warrant that you are not located in, under control of, or a national
        or resident of any such country or on any such list.
      </p>

      <Header as="h2">Amendments to this Agreement</Header>

      <p>
        Joatspace Inc. reserves the right, at its sole discretion, to modify or
        replace this Agreement at any time. If a revision is material we will
        provide at least 30 days' notice prior to any new terms taking effect.
        What constitutes a material change will be determined at our sole
        discretion.
      </p>

      <p>
        By continuing to access or use our Application after any revisions
        become effective, you agree to be bound by the revised terms. If you do
        not agree to the new terms, you are no longer authorized to use the
        Application.
      </p>

      <Header as="h2">Governing Law</Header>

      <p>
        The laws of New York, United States, excluding its conflicts of law
        rules, shall govern this Agreement and your use of the Application. Your
        use of the Application may also be subject to other local, state,
        national, or international laws.
      </p>

      <p>
        This Agreement shall not be governed by the United Nations Convention on
        Contracts for the International Sale of Good.
      </p>

      <Header as="h2">Contact Information</Header>

      <p>If you have any questions about this Agreement, please contact us.</p>

      <Header as="h2">Entire Agreement</Header>

      <p>
        The Agreement constitutes the entire agreement between you and Joatspace
        Inc. regarding your use of the Application and supersedes all prior and
        contemporaneous written or oral agreements between you and Joatspace
        Inc..
      </p>

      <p>
        You may be subject to additional terms and conditions that apply when
        you use or purchase other Joatspace Inc.'s services, which Joatspace
        Inc. will provide to you at the time of such use or purchase.
      </p>
    </Container>

    <Divider />
  </Grid>
);

class Eula extends Component {
  componentDidUpdate(prevProps) {
    ReactDOM.findDOMNode(this).scrollIntoView();
    // Location
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <Meta
          title="End-User License Agreement"
          description="End-User License Agreement. The Agreement constitutes the entire agreement between you and Joatspace Inc. regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Joatspace Inc.."
          url="https://www.joatspace.com/eula"
          copyright="Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
        />
        <MobileNav /> {/* Custom Menu for Mobile Only */}
        <CustomMenu /> {/* Custom Menu for Desktop and Tablet Only */}
        <PageHeader
          title="End-User License Agreement"
          desc="Last updated: September 06, 2018"
        />
        <Agreement />
        <Footer />
      </div>
    );
  }
}

export default Eula;
