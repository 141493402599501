import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "../index.css";

class Meta extends Component {
  render() {
    const { title, description, url, copyright } = this.props;

    return (
      <Helmet titleTemplate="JoatSpace | %s" defaultTitle="JoatSpace">
        <title itemProp="name" lang="en">
          {title}
        </title>
        <meta httpEquiv="Content-Type" content="text/html" charSet="utf-8" />
        <base target="_blank" href="." />
        <meta name="description" content={description} />
        <meta name="copyright" content={copyright} />
        <meta
          name="keywords"
          content="joatspace, taskers, joatspaceit, joat space, joat, tasker, gardener, plumber, photographer, app.joatspace.com, hire someone, become a tasker"
        />
        <meta name="robots" content="index,follow" />
        <meta name="DC.title" content={title} />
        <meta name="theme-color" content="#000000" />
        <meta
          name="google-site-verification"
          content="Ds07lErgvWDwXzCCJhyyXTiwQ3hYz-K_IQwE4LLUHNI"
        />

        {/* Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="JoatSpace" />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.joatspace.com//static/media/Joatspace.64efc7b3.png"
        />
        <meta property="og:url" content={url} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content={description} />
        <meta name="twitter:site" content="@joatspace" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.joatspace.com//static/media/Joatspace.64efc7b3.png"
        />
        <meta name="twitter:app:name:iphone" content="JoatSpace" />
        <meta name="twitter:app:id:iphone" content="1434912789" />
        <meta
          name="twitter:app:url:iphone"
          content="https://apps.apple.com/us/app/joatspace/id1434912789"
        />
        <meta name="twitter:app:name:ipad" content="JoatSpace" />
        <meta name="twitter:app:id:ipad" content="1434912789" />
        <meta
          name="twitter:app:url:ipad"
          content="https://apps.apple.com/us/app/joatspace/id1434912789"
        />
        <meta name="twitter:app:name:googleplay" content="JoatSpace" />
        <meta
          name="twitter:app:id:googleplay"
          content="com.joatspace.android"
        />
        <meta
          name="twitter:app:url:googleplay"
          content="https://play.google.com/store/apps/details?id=com.joatspace.android"
        />

        {/* multiple link elements */}
        <link rel="canonical" href={url} />
        <link rel="manifest" href="manifest.json" />
        <link rel="shortcut icon" href="favicon.ico" />
        <link
          rel="alternate"
          href="android-app://{com.joatspace.android}/{joatspace/home}"
        />
        <link rel="alternate" href="ios-app://{1434912789}/{joatspace/home}" />
        <link
          rel="apple-touch-icon"
          href="https://lh3.googleusercontent.com/LPGmoEkI2BJrsT7IypGo503rzT9jti8Z9mJEtOQasrucofpxYF2mKmZe9chq-L2l=s180-rw"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://lh3.googleusercontent.com/LPGmoEkI2BJrsT7IypGo503rzT9jti8Z9mJEtOQasrucofpxYF2mKmZe9chq-L2l=s180-rw"
        />

        {/* inline script elements */}
        <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "https://www.joatspace.com",
            "name": "JoatSpace",
            "logo": "https://lh3.googleusercontent.com/LPGmoEkI2BJrsT7IypGo503rzT9jti8Z9mJEtOQasrucofpxYF2mKmZe9chq-L2l=s180-rw",
            "sameAs": [
                "https://www.facebook.com/joatspace/",
                "https://www.instagram.com/joatspace/",
                "https://twitter.com/joatspace",
                "https://www.linkedin.com/company/joatspace"
            ],
            "operatingSystem": "ANDROID",
            "applicationCategory": "http://schema.org/GameApplication",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "8864"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "email": "support@joatspace.com",
                "telephone": "+1-347-508-1890",
                "contactType": "customer service"
            },
            "mainEntity": [{
                "@type": "Question",
                "name": "How Do I Become A Tasker?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Download JoatSpace. Open the JoatSpace App, Click on the Profile Icon and Click on the ‘Become a Tasker Today’ Button. Complete the Application page by clicking and entering your Home Address or City, Then enter your email address and phone number. Now enter your Occupation and years of experience. Let’s attach a Government Identification Card now. Read and Accept the terms and conditions. You can submit your application. Your application should be accepted in 48 hours."
                }
            }, {
                "@type": "Question",
                "name": "Are you available in my Country?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "JoatSpace is available in Nigeria, Ghana, Kenya, Uganda and Tanzania"
                }
            }, {
                "@type": "Question",
                "name": "How Do I Search for a Tasker?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Click on <bold>All taskers near me</bold> in Home. Search using the Occupation, and you can also change your location by clicking on the address and updating it. Now, your location should be updated and you should be able to view all the taskers in that location. Click on the Tasker to open the Tasker Profile. You should see the Tasker Rating, the Tasker Distance from you, and the numbers of Jobs the tasker has completed with the reviews. "
                }
            }, {
                "@type": "Question",
                "name": "Can't send a Request",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You wouldn't be able to send a request if you have a pending request. If you don't have a pending request. <br/> <p>Contact Customer Service or reach us to  <a href='mailto:support@joatspace.com'>JoatSpace Support</a> </p>"
                }
            }, {
                "@type": "Question",
                "name": "What is JoatSpace?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "JoatSpace is a mobile two-sided marketplace, where you can find individuals whom we call <bold>taskers</bold> to help with everyday tasks. With JoatSpace, Get instant access to the top-rated taskers around you."
                }
            }]
        }
        `}</script>

        {/* noscript elements */}
        <noscript>{`
        <link rel="stylesheet" type="text/css" href="foo.css" />
        `}</noscript>
      </Helmet>
    );
  }
}

export default Meta;
