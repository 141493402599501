import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import logo from "../img/logowithGradient.svg";
import "../index.css";
import { Link } from "react-router-dom";

const FooterLink = props => (
  <Grid.Column
    width={props.width}
    style={{
      fontFamily: "Open Sans",
      fontWeight: "300",
      fontSize: "1.125rem",
      textAlign: "center",
      letterSpacing: "0.2px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      marginTop: "1rem"
    }}
  >
    {props.external ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.to}
        style={{
          color: "#ffffff"
        }}
      >
        {props.text}
      </a>
    ) : (
      <Link
        to={props.to}
        style={{
          color: "#ffffff"
        }}
      >
        {props.text}
      </Link>
    )}
  </Grid.Column>
);

const DesktopFooterLink = () => (
  <Grid.Row only="computer">
    <FooterLink to="/terms" width={2} text="Terms of Service" />
    <FooterLink to="/privacy-policy" width={2} text="Privacy Policy" />
    <FooterLink to="/support" text="Support" />
    <FooterLink
      external={true}
      to="https://twitter.com/joatspace"
      text="Twitter"
    />
    <FooterLink
      external={true}
      to="https://www.instagram.com/joatspace/"
      text="Instagram"
    />
    <FooterLink to="/faq" text="FAQ" />
  </Grid.Row>
);

const MobileFooterLink = () => (
  <Grid.Row only="mobile tablet" columns={1}>
    <FooterLink to="/terms" text="Terms of Service" />
    <FooterLink to="/privacy-policy" text="Privacy Policy" />
    <FooterLink to="/support" text="Support" />
    <FooterLink
      external={true}
      to="https://twitter.com/joatspace"
      text="Twitter"
    />
    <FooterLink
      external={true}
      to="https://www.instagram.com/joatspace/"
      text="Instagram"
    />
    <FooterLink to="/faq" text="FAQ" />
  </Grid.Row>
);

class Footer extends Component {
  render() {
    return (
      <Grid
        centered
        padded
        style={{
          backgroundColor: "#2a2a2a"
        }}
      >
        <Grid.Row
          style={{
            marginTop: "2rem"
          }}
        >
          <a href=".">
            <Image src={logo} />
          </a>
        </Grid.Row>

        <DesktopFooterLink />
        <MobileFooterLink />

        <Grid.Row
          style={{
            marginTop: "1.40rem"
          }}
        >
          <label
            style={{
              fontFamily: "Open Sans",
              fontWeight: "300",
              fontSize: "0.875rem",
              textAlign: "center",
              letterSpacing: "1px",
              fontStyle: "normal",
              color: "#8f8f8f"
            }}
          >
            Copyright © {new Date().getFullYear()} JoatSpace. All Rights Reserved.
          </label>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Footer;
