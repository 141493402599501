import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import "../index.css";

const PageHeaderTitle = props => (
  <Grid.Row
    centered
    style={{
      marginTop: "12rem",
      marginLeft: "0.5rem",
      marginRight: "0.5rem"
    }}
  >
    <label
      style={{
        fontFamily: "Open Sans",
        fontSize: "2.5rem",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "0.075rem",
        textAlign: "center",
        color: "#3f3f3f"
      }}
    >
      {props.title}
    </label>
  </Grid.Row>
);

const PageHeaderDesc = props => (
  <Grid.Row centered>
    <label
      style={{
        fontFamily: "Open Sans",
        fontSize: "1.125rem",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "0.6px",
        textAlign: "center",
        color: "#7a7a7a",
        marginBottom: "4.25rem"
      }}
    >
      {props.desc}
    </label>
  </Grid.Row>
);

class PageHeader extends Component {
  render() {
    return (
      <Grid
        padded
        style={{
          backgroundColor: "#f8f8f8"
        }}
      >
        <div id="pageHeader" />
        <PageHeaderTitle title={this.props.title} />
        <PageHeaderDesc desc={this.props.desc} />
      </Grid>
    );
  }
}

export default PageHeader;
