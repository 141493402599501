import React, { Component } from "react";
import { Grid, Image, Button, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "../img/logowithGradient.svg";
import "../index.css";

class MobileNav extends Component {
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  downloadAppoadApp = () => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://itunes.apple.com/us/app/joatspace/id1434912789";
    }

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.joatspace.android";
    }

    //Update #2
    if (
      !navigator.userAgent.match(
        /(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/
      )
    ) {
      window.location.href = "https://www.joatspace.com/download"; //Desktop Browser
    }
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Grid>
        <Grid.Row only="mobile" columns={1}>
          <Menu
            stackable
            style={{
              backgroundColor: "#2a2a2a",
              width: "100%",
              paddingBottom: "1rem",
              paddingLeft: "1rem"
            }}
          >
            <Menu.Item>
              <a href=".">
                <Image
                  src={logo}
                  style={{
                    marginTop: "1rem",
                    marginLeft: "3rem"
                  }}
                />
              </a>
            </Menu.Item>

            <Menu.Item
              name="terms"
              active={activeItem === "terms"}
              onClick={this.handleItemClick}
            >
              <Link to="/terms" className="Link">
                Terms of Service
              </Link>
            </Menu.Item>

            <Menu.Item
              name="privacy"
              active={activeItem === "privacy"}
              onClick={this.handleItemClick}
            >
              <Link to="/privacy-policy" className="Link">
                Privacy Policy
              </Link>
            </Menu.Item>

            <Menu.Item
              name="eula"
              active={activeItem === "eula"}
              onClick={this.handleItemClick}
            >
              <Link to="/eula" className="Link">
                EULA
              </Link>
            </Menu.Item>

            <Menu.Item
              name="support"
              active={activeItem === "support"}
              onClick={this.handleItemClick}
            >
              <Link to="/support" className="Link">
                Support
              </Link>
            </Menu.Item>

            <Menu.Item
              name="support"
              active={activeItem === "support"}
              onClick={this.handleItemClick}
            >
              <Button onClick={this.downloadAppoadApp} className="Link">
                Download
              </Button>
            </Menu.Item>
          </Menu>
        </Grid.Row>
      </Grid>
    );
  }
}

export default MobileNav;
