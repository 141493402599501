import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore.js";
import "./index.css";

import MainPage from "./components/WelcomePage";

import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Eula from "./components/Eula";
import Support from "./components/Support";
import errorPage from "./components/404Page";
import Download from "./components/Download";

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/eula" component={Eula} />
          <Route path="/support" component={Support} />
          <Route path="/download" component={Download} />
          <Route component={errorPage} />
        </Switch>
      </Provider>
    );
  }
}

export default App;
