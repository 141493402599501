import React, { Component } from "react";
import ReactDOM from "react-dom";

import Home from "./Home";
import HowItWorks from "../container/HowItWorks";
import BecomeATasker from "../container/BecomeATasker";
import Footer from "../container/Footer";
import Meta from "./Meta";

class WelcomePage extends Component {
  componentDidUpdate(prevProps) {
    ReactDOM.findDOMNode(this).scrollIntoView();
    // Location
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <Meta
          title="Official Site"
          description="Get instant access to top-rated taskers around you. Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
          url="https://www.joatspace.com"
          copyright="Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
        />
        <Home />
        <HowItWorks />
        <BecomeATasker />
        <Footer />
      </div>
    );
  }
}

export default WelcomePage;
