import React, { Component } from "react";
import { Image, Grid } from "semantic-ui-react";
import search from "../img/search.svg";
import sendARequestIcon from "../img/sendRequestIcon.svg";
import done from "../img/done.svg";
import "../index.css";

const HowItWorksBody = props => (
  <Grid.Column mobile={16} tablet={8} computer={4} verticalAlign="middle">
    <Image
      centered
      src={props.img}
      size="small"
      style={{
        marginTop: "1rem"
      }}
    />

    <Grid.Row
      centered
      style={{
        marginTop: "2.6rem"
      }}
    >
      <label
        style={{
          fontFamily: "Open Sans",
          fontSize: "1.8rem",
          fontStyle: "normal",
          fontStretch: "normal",
          textAlign: "center",
          letterSpacing: "0.8px",
          color: "#3f3f3f"
        }}
      >
        {props.title}
      </label>
    </Grid.Row>

    <Grid.Row
      centered
      style={{
        marginTop: "1.3rem",
        marginBottom: "10rem"
      }}
    >
      <label
        style={{
          fontFamily: "Open Sans",
          fontWeight: 300,
          fontSize: "1.125rem",
          fontStyle: "normal",
          fontStretch: "normal",
          textAlign: "center",
          letterSpacing: "0.5px",
          color: "#3f3f3f"
        }}
      >
        {props.desc}
      </label>
    </Grid.Row>
  </Grid.Column>
);

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);

  render() {
    return (
      <Grid padded>
        <div ref={this.props.ref} />
        <Grid.Row centered>
          <label
            style={{
              marginTop: "8.5rem",
              fontFamily: "Open Sans",
              fontWeight: "bold",
              fontSize: "2.5rem",
              textAlign: "center",
              letterSpacing: "1px",
              fontStyle: "normal",
              color: "#3f3f3f"
            }}
          >
            How it works
          </label>
        </Grid.Row>

        <Grid.Row centered textAlign="center" columns={3}>
          <HowItWorksBody
            img={search}
            title="Search"
            desc="Select from a variety of taskers around you and select 
                    the day and time you'd like the tasker to be available. "
          />
          <HowItWorksBody
            img={sendARequestIcon}
            title="Send a Request"
            desc="Get approved by the Tasker, 
                    and start chatting with the tasker right in the app."
          />
          <HowItWorksBody
            img={done}
            title="Job gets done"
            desc="Tasker arrives, gets the 
                    job done. When your task is complete. Give the tasker a rating and payment will happen seamlessly and securely through the app."
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default HowItWorks;
