import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import googlePlay from "../img/google-play-badge.png";
import appStore from "../img/iOSAppIcon.svg";
import "../index.css";

class AppStoreIcon extends Component {
  render() {
    return (
      <Image.Group size="small">
        {/* App Store */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://itunes.apple.com/us/app/joatspace/id1434912789"
        >
          <Image src={appStore} />
        </a>

        {/* Google Play  */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.joatspace.android"
        >
          <Image src={googlePlay} />
        </a>
      </Image.Group>
    );
  }
}

export default AppStoreIcon;
