import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import "../index.css";
import errorPage from "../img/404bg.svg";
import Link from "react-router-dom/Link";
import Meta from "./Meta";

class ErrorPage extends Component {
  render() {
    return (
      <Grid
        style={{
          backgroundImage: `url(${errorPage})`,
          backgroundSize: "cover",
          height: "100vh"
        }}
      >
        <Meta
          title="404"
          description="There's nothing here!. Not all who wander are lost. But you certainly are"
          url="https://www.joatspace.com/404"
          copyright="Need to hire someone? Anyone, from a photographer to a gardener, Joatspace will get you connected in seconds! #joatspaceit"
        />
        <Grid.Column
          width={7}
          floated="right"
          style={{
            marginTop: "25%"
            // marginRight: '5rem'
          }}
        >
          <h2
            style={{
              fontFamily: "OpenSans-Bold",
              fontSize: "1.83rem",
              color: "#3F3F3F",
              letterSpacing: "0.57px",
              textAlign: "left"
            }}
          >
            There's nothing here!
          </h2>
          <p
            style={{
              fontFamily: "OpenSans-Light",
              fontSize: "1.33rem",
              color: "#7A7A7A",
              letterSpacing: "0.49px"
            }}
          >
            Not all who wander are lost. But you certainly are
          </p>

          <Button
            onClick={this.goToHomepage}
            style={{
              /* Rectangle 2: */
              width: "19rem",
              height: "3rem",
              marginTop: "3rem",
              background: "#F8F8F8",
              borderRadius: "40px",
              fontFamily: "OpenSans-Bold",
              fontSize: "16px",
              color: "#49D2AA",
              letterSpacing: "0.03px",
              textAlign: "center"
            }}
          >
            <Link
              to="/"
              style={{
                color: "#49D2AA"
              }}
            >
              GO TO HOMEPAGE
            </Link>
          </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ErrorPage;
